export const sectionNumberCss = ({ theme, color }) => ({
  background: theme.colors[color][10],
  borderRadius: theme.radii[6],
  color: theme.colors[color][50],
  alignItems: `center`,
  display: `flex`,
  fontWeight: theme.fontWeights.bold,
  justifyContent: `center`,
  height: theme.space[8],

  width: theme.space[8],
})
